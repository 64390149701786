.project {
  width: 100%;
  /*height: calc(100vh - 100px);*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project h1 {
  margin-top: 50px;
  color: #3e497a;
  font-size: 35px;
}
.project img {
  width: 700px;
  border-radius: 10px;
}

.project p {
  font-size: 40px;
  color: #3e497a;
}

.slide-container
{
	width: 700px;
}

fieldset
{
	margin: 30px 0 5px;
	width: 680px;
	border-radius: 8px;
	padding: 10px 20px 20px;
}

fieldset legend
{
	font-weight: bold;
	padding: 2px 5px;
	border: 1px solid #235;
	border-radius: 4px;
}

.description
{
	margin-top: 10px;
}

.technology
{
	margin-top: 20px;
}

.versions
{
	margin-top: -1em;
	margin-left: 80px;
}

.external-links
{
	text-align: center;
	margin: 25px 0 0px;
	padding: 5px 0;
}

.buttonContainer
{
	margin-top: 10px;
}

.buttonContainer a
{
	margin: 0 10px;
}

.external-links svg
{
	vertical-align: middle;
}


