.navbar {
  width: 100%;
  height: 100px;
  background: #21325e;
  background: #215e32;
  background: #235;
  position: sticky;
  top: 0;
  z-index: 20;
  transition: height 0.5s;
}

.navbar.shrink
{
	height: 70px;
	transition: height 0.5s;
}

.navbar.shrink a
{
	font-size: 22px;
	transition: font-size 0.5s;
}

.navbar img#thehooman
{
	left: 10px;
	top: 10px;
	height: 80px;
	position: absolute;
	transition: height 0.5s, left 0.5s, top 0.5s;
}

.navbar.shrink img#thehooman
{
	left: 5px;
	top: 5px;
	height: 60px;
	transition: height 0.5s, left 0.5s, top 0.5s;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 25px;
  transition: font-size 0.5s;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

#open {
  height: 100vh;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .navbar a {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
